.post {
  background-color: var(--background-color);
}
.tooltip {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: var(--border-color);
  text-underline-offset: auto;
  cursor: pointer;
}
.border-post {
  border-color: var(--border-color);
  box-shadow: 0.5em 0.5em 0 0 var(--border-color);
  padding: 1.5em;
  margin-left: 1.0em;
  margin-right: 1.0em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.border-blue-400 {
  border-color: #83e4fa;
}
