.code-block {
  border: 2px solid var(--border-color);
  font-weight: 800;
  box-shadow: 0.5em 0.5em 0 0 var(--border-color);
}
.code-header {
  border-color: var(--border-color);
  border-style: solid;
  border-bottom: 2px;
}
