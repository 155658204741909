.blinking-cursor::after {
  content: '█';
  display: inline-block;
  animation: blink 2s steps(1) infinite;
}

@keyframes blink {
  0%,
  50% {
    content: '█';
  }
  51%,
  100% {
    content: '_';
  }
}
