/* .link-accent {
  color: var(--link-button-accent-color);
}
.link-color {
  color: var(--link-button-color);
}
.link-color .invert-text {
  color: var(--link-button-accent-color-dark);
}
.link-accent-dark {
  color: var(--link-button-accent-color-dark);
}
.link-accent-dark .invert-text {
  color: var(--link-button-accent-color);
}
.link-color-dark {
  color: var(--link-button-color-dark);
}
.link-color-dark .invert-text {
  color: var(--link-button-color);
}
.invert-bg {
  background-color: var(--text-color);
}
.invert-text {
  color: var(--background-color);
} */
