.hovered {
  border: 2px solid var(--hover-border-color);
  box-shadow: 0.5em 0.5em 0 0 var(--border-color);
}
.not-hovered {
  border: 2px solid var(--border-color);
  box-shadow: 0.5em 0.5em 0 0 var(--border-color);
}

.focused {
  border: 2px solid var(--focus-border-color);
  box-shadow: 0.5em 0.5em 0 0 var(--border-color);
}

.passwordinput {
  background-color: var(--background-color);
  color: var(--text-color);
}


